import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import TimeModal from "./TimeModal";
import LicenseModal from "./LicenseModal";
import WalletModal from "./WalletModal";
const LicenseControlModal = ({
  openModal,
  setOpenModal,
  modalData,
  modalType,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [animateModal, setAnimateModal] = useState(false);

  useEffect(() => {
    if (openModal) {
      setShowModal(true);
      setTimeout(() => setAnimateModal(true), 100);
    } else {
      setAnimateModal(false);
      const timer = setTimeout(() => setShowModal(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [openModal]);


  return (
    <>
      {showModal && (
        <div
          className={`fixed top-0 left-0 z-50 flex h-full w-full  items-center justify-center bg-[#63616154] backdrop-blur-sm transition-opacity duration-300 dark:bg-[#9ac8e246] ${
            animateModal ? "opacity-100" : "opacity-0"
          }`}
        >

      {
        modalType === "date" 
        ? <TimeModal setOpenModal={setOpenModal} path={"https"} data={modalData} />
        : modalType ==="license" ? <LicenseModal setOpenModal={setOpenModal} path={"https"} data={modalData} />
        : modalType === "wallet" ? <WalletModal setOpenModal={setOpenModal} path={"https"} data={modalData} />
        :<></>
        
      }
        </div>
      )}
    </>
  );
};

export default LicenseControlModal;
