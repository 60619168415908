import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdDone } from "react-icons/md";

const CreateBroker = ({ openModal, setOpenModal }) => {
  const [showModal, setShowModal] = useState(false);
  const [animateModal, setAnimateModal] = useState(false);

  useEffect(() => {
    if (openModal) {
      setShowModal(true);
      setTimeout(() => setAnimateModal(true), 100);
    } else {
      setAnimateModal(false);
      const timer = setTimeout(() => setShowModal(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [openModal]);

  return (
    <>
      {showModal && (
        <div
          onClick={() => setOpenModal(false)}
          className={`fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-[#63616154] backdrop-blur-sm transition-opacity duration-300 dark:bg-[#9ac8e246] ${
            animateModal ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`w-[80%] transform transition-transform duration-300 ${
              animateModal ? "scale-100" : "scale-75"
            } light-scroll relative m-10 rounded-lg border-4 bg-white shadow dark:border-navy-400 dark:bg-navy-700`}
          >
            <div className="flex w-full items-start justify-between rounded-t border-b p-5">
              <h3 className="w-full text-xl font-semibold dark:text-white">
                افزودن کارگزاری
              </h3>

              <button
                onClick={() => setOpenModal(false)}
                type="button"
                className="bg-transparent ml-auto inline-flex items-center rounded-lg p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                data-modal-toggle="product-modal"
              >
                <svg
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="space-y-6 p-6">
              <form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      for="broker-name"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                    >
                      نام کارگزاری
                    </label>
                    <input
                      type="text"
                      name="broker-name"
                      id="broker-name"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50  p-2.5 text-gray-900 shadow-sm outline-navy-200 focus:border-cyan-600 focus:ring-cyan-600 dark:border-navy-500 dark:bg-navy-900 dark:text-gray-300 sm:text-sm"
                      placeholder="نام کارگزاری"
                      required=""
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      for="broker-code"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                    >
                      کد کارگزاری
                    </label>
                    <input
                      type="text"
                      name="broker-code"
                      id="broker-code"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50  p-2.5 text-gray-900 shadow-sm outline-navy-200 focus:border-cyan-600 focus:ring-cyan-600 dark:border-navy-500 dark:bg-navy-900 dark:text-gray-300 sm:text-sm"
                      placeholder="کد کارگزاری"
                      required=""
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      for="brand"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                    >
                      شماره تلفن
                    </label>
                    <input
                      type="text"
                      name="brand"
                      id="brand"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50  p-2.5 text-gray-900 shadow-sm outline-navy-200 focus:border-cyan-600 focus:ring-cyan-600 dark:border-navy-500 dark:bg-navy-900 dark:text-gray-300 sm:text-sm"
                      placeholder="شماره تلفن"
                      required=""
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      for="price"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                    >
                      موجودی کیف پول
                    </label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50  p-2.5 text-gray-900 shadow-sm outline-navy-200 focus:border-cyan-600 focus:ring-cyan-600 dark:border-navy-500 dark:bg-navy-900 dark:text-gray-300 sm:text-sm"
                      placeholder="(تومان)"
                      required=""
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      for="broker-address"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                    >
                      آدرس
                    </label>
                    <textarea
                      id="broker-address"
                      rows="3"
                      className="block w-full resize-none rounded-lg border border-gray-300  bg-gray-50 p-4 text-gray-900 outline-navy-200 focus:border-cyan-600 focus:ring-cyan-600 dark:border-navy-500 dark:bg-navy-900 dark:text-gray-300 sm:text-sm"
                      placeholder="آدرس دقیق کارگزاری"
                    ></textarea>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      for="broker-address"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                    >
                      آپلود لوگو
                    </label>
                    <input
                      type="file"
                      id="actual-btn"
                      accept="image/png, image/gif, image/jpeg"
                      hidden
                    />
                    <label htmlFor="actual-btn" className="">
                      <div className="w-full h-[75%] rounded-xl border-2 border-dashed border-gray-600 flex items-center justify-center">
                        <span className="text-gray-400">لوگو خود را بکشید و رها کنید </span>
                      </div>
                    </label>
                  </div>
                </div>
              </form>
            </div>

            <div className="rounded-b border-t border-gray-200 p-6">
              <button className="group flex items-center justify-center rounded-2xl bg-navy-500 p-3 text-white transition-all ease-out hover:bg-navy-400">
                افزودن
                <MdDone className="mr-2 transition-all ease-out group-hover:text-xl" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateBroker;
