import axios from "axios";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoMdChatbubbles } from "react-icons/io";
import { MdLogin } from "react-icons/md";
import { PiChartLineDuotone } from "react-icons/pi";
import { SiChatbot } from "react-icons/si";

const LicenseModal = ({ path, data, setOpenModal }) => {
  // const updateLicense = () =>{
  //     axios.post('path',data).then((response) =>{
  //         console.log(response)
  //     },(error)=>{
  //         console.log(error)
  //     })
  // }

  return (
    <div className="w-[50%] rounded-2xl bg-white sm-max:h-[650px] md-max:w-[80%] overflow-x-hidden light-scroll">
      <div className="flex w-full items-start justify-between rounded-t border-b p-5">
        <h3 className="w-full text-xl font-semibold dark:text-white">
          مدیریت لایسنس
        </h3>

        <button
          onClick={() => setOpenModal(false)}
          type="button"
          className="bg-transparent ml-auto inline-flex items-center rounded-lg p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
          data-modal-toggle="product-modal"
        >
          <svg
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex items-start justify-between md-max:flex-col-reverse md-max:items-center px-3 py-5">
      <div className="flex xl-max:flex-col h-full w-full items-center justify-center gap-2 px-2">
        <div className="flex h-full w-full flex-col items-center gap-5 rounded-xl  bg-white dark:bg-navy-700 dark:text-white border-2 dark:border-0 px-2 pb-2 pt-4 shadow-inner">
          <SiChatbot className="text-3xl" />

          <h2 className=" text-center text-xl font-bold">چت  مصنوعی</h2>

          <div className="flex w-full items-center justify-evenly">
            <div className="flex items-center justify-center">
              <label className="inline-flex cursor-pointer items-center">
                <input type="checkbox" value="" className="peer sr-only" />
                <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-500 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                <span className="text-sm font-medium text-gray-700 ms-3 dark:text-gray-300"></span>
              </label>
            </div>
            <AiOutlineQuestionCircle className="cursor-pointer text-2xl" />
          </div>
        </div>
        <div className="flex h-full w-full flex-col items-center gap-5 rounded-xl  bg-white dark:bg-navy-700 dark:text-white border-2 dark:border-0 px-2 pb-2 pt-4 shadow-inner">
          <MdLogin className="text-3xl" />

          <h2 className=" text-center text-xl font-bold">ورود کاربر با پسورد</h2>

          <div className="flex w-full items-center justify-evenly">
            <div className="flex items-center justify-center">
              <label className="inline-flex cursor-pointer items-center">
                <input type="checkbox" value="" className="peer sr-only" />
                <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-500 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                <span className="text-sm font-medium text-gray-700 ms-3 dark:text-gray-300"></span>
              </label>
            </div>
            <AiOutlineQuestionCircle className="cursor-pointer text-2xl" />
          </div>
        </div>
      </div>
      <div className="flex xl-max:flex-col h-full w-full items-center justify-center gap-2 px-2">
        <div className="flex h-full w-full flex-col items-center gap-5 rounded-xl  bg-white dark:bg-navy-700 dark:text-white border-2 dark:border-0 px-2 pb-2 pt-4 shadow-inner">
          <PiChartLineDuotone className="text-3xl" />

          <h2 className=" text-center text-xl font-bold">نمودار تکنیکال</h2>

          <div className="flex w-full items-center justify-evenly">
            <div className="flex items-center justify-center">
              <label className="inline-flex cursor-pointer items-center">
                <input type="checkbox" value="" className="peer sr-only" />
                <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-500 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                <span className="text-sm font-medium text-gray-700 ms-3 dark:text-gray-300"></span>
              </label>
            </div>
            <AiOutlineQuestionCircle className="cursor-pointer text-2xl" />
          </div>
        </div>
        <div className="flex h-full w-full flex-col items-center gap-5 rounded-xl  bg-white dark:bg-navy-700 dark:text-white border-2 dark:border-0 px-2 pb-2 pt-4 shadow-inner">
          <IoMdChatbubbles className="text-3xl" />

          <h2 className=" text-center text-xl font-bold">شبکه اجتماعی</h2>

          <div className="flex w-full items-center justify-evenly">
            <div className="flex items-center justify-center">
              <label className="inline-flex cursor-pointer items-center">
                <input type="checkbox" value="" className="peer sr-only" />
                <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-500 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                <span className="text-sm font-medium text-gray-700 ms-3 dark:text-gray-300"></span>
              </label>
            </div>
            <AiOutlineQuestionCircle className="cursor-pointer text-2xl" />
          </div>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default LicenseModal;
