import React, { useEffect, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoMdChatbubbles } from "react-icons/io";
import { MdDone, MdLogin } from "react-icons/md";
import { PiChartLineDuotone } from "react-icons/pi";
import { SiChatbot } from "react-icons/si";

const BrokerDetails = ({ openModal, setOpenModal }) => {
  const [showModal, setShowModal] = useState(false);
  const [animateModal, setAnimateModal] = useState(false);

  useEffect(() => {
    if (openModal) {
      setShowModal(true);
      setTimeout(() => setAnimateModal(true), 100);
    } else {
      setAnimateModal(false);
      const timer = setTimeout(() => setShowModal(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [openModal]);

  return (
    <>
      {showModal && (
        <div
          onClick={() => setOpenModal(false)}
          className={`fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-[#63616154] backdrop-blur-sm transition-opacity duration-300 dark:bg-[#9ac8e246] ${
            animateModal ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`w-[80%] transform overflow-y-auto transition-transform duration-300 ${
              animateModal ? "scale-100" : "scale-75"
            } light-scroll relative m-10 rounded-lg border-4 bg-white shadow dark:border-navy-400 dark:bg-navy-700`}
          >
            <div className="flex flex-col ">
              {/* top-bar */}
              <div className="flex w-full items-start justify-between rounded-t border-b p-5">
                <h3 className="w-full text-xl font-semibold dark:text-white">
                  افزودن کارگزاری
                </h3>

                <button
                  onClick={() => setOpenModal(false)}
                  type="button"
                  className="bg-transparent ml-auto inline-flex items-center rounded-lg p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                  data-modal-toggle="product-modal"
                >
                  <svg
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* body */}
              <div className="flex items-start md-max:flex-col sm-max:h-[550px]">
                {/* broker information */}
                <div className="full flex h-full w-full flex-col">
                  
                  <div className="h-full space-y-6 p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="broker-name"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                        >
                          نام کارگزاری
                        </label>
                        <h3 className="text-xl font-bold text-white">
                          سهم آشنا
                        </h3>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="broker-code"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                        >
                          کد کارگزاری
                        </label>
                        <h3 className="text-xl font-bold dark:text-white">
                          01-45325
                        </h3>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="brand"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                        >
                          شماره تلفن
                        </label>
                        <h3 className="text-xl font-bold dark:text-white">
                          021-452597
                        </h3>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="price"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                        >
                          موجودی کیف پول
                        </label>
                        <h3 className="text-xl font-bold dark:text-white">
                          10،000،000
                        </h3>
                      </div>
                      <div className="col-span-full">
                        <label
                          for="broker-address"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
                        >
                          آدرس
                        </label>
                        <p className="dark:text-white">
                          تهران ، سعادت آباد ، بلوار یادگار امام ، بالاتر از
                          میدون کاج
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* broker modules */}
                <div className="full flex w-full flex-col items-center gap-2 p-2">
                  <div className="w-full gap-2">
                    <div className="flex h-full w-full items-center justify-center gap-2 px-2">
                      <div className="flex h-full w-full flex-col items-center gap-5 rounded-xl  border-2 bg-white px-2 pb-2 pt-4 shadow-inner dark:border-0 dark:bg-navy-800 dark:text-white">
                        <SiChatbot className="text-3xl" />

                        <h2 className=" text-center text-xl font-bold">
                          چت مصنوعی
                        </h2>

                        <div className="flex w-full items-center justify-evenly">
                          <div className="flex items-center justify-center">
                            <label className="inline-flex cursor-pointer items-center">
                              <input
                                type="checkbox"
                                value=""
                                className="peer sr-only"
                              />
                              <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-500 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                              <span className="text-sm font-medium text-gray-700 ms-3 dark:text-gray-300"></span>
                            </label>
                          </div>
                          <AiOutlineQuestionCircle className="cursor-pointer text-2xl" />
                        </div>
                      </div>
                      <div className="flex h-full w-full flex-col items-center gap-5 rounded-xl  border-2 bg-white px-2 pb-2 pt-4 shadow-inner dark:border-0 dark:bg-navy-800 dark:text-white">
                        <MdLogin className="text-3xl" />

                        <h2 className=" text-center text-xl font-bold">
                          ورود با پسورد
                        </h2>

                        <div className="flex w-full items-center justify-evenly">
                          <div className="flex items-center justify-center">
                            <label className="inline-flex cursor-pointer items-center">
                              <input
                                type="checkbox"
                                value=""
                                className="peer sr-only"
                              />
                              <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-500 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                              <span className="text-sm font-medium text-gray-700 ms-3 dark:text-gray-300"></span>
                            </label>
                          </div>
                          <AiOutlineQuestionCircle className="cursor-pointer text-2xl" />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 flex h-full w-full items-center justify-center gap-2 px-2">
                      <div className="flex h-full w-full flex-col items-center gap-5 rounded-xl  border-2 bg-white px-2 pb-2 pt-4 shadow-inner dark:border-0 dark:bg-navy-800 dark:text-white">
                        <PiChartLineDuotone className="text-3xl" />

                        <h2 className=" text-center text-xl font-bold">
                          نمودار تکنیکال
                        </h2>

                        <div className="flex w-full items-center justify-evenly">
                          <div className="flex items-center justify-center">
                            <label className="inline-flex cursor-pointer items-center">
                              <input
                                type="checkbox"
                                value=""
                                className="peer sr-only"
                              />
                              <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-500 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                              <span className="text-sm font-medium text-gray-700 ms-3 dark:text-gray-300"></span>
                            </label>
                          </div>
                          <AiOutlineQuestionCircle className="cursor-pointer text-2xl" />
                        </div>
                      </div>
                      <div className="flex h-full w-full flex-col items-center gap-5 rounded-xl  border-2 bg-white px-2 pb-2 pt-4 shadow-inner dark:border-0 dark:bg-navy-800 dark:text-white">
                        <IoMdChatbubbles className="text-3xl" />

                        <h2 className=" text-center text-xl font-bold">
                          شبکه اجتماعی
                        </h2>

                        <div className="flex w-full items-center justify-evenly">
                          <div className="flex items-center justify-center">
                            <label className="inline-flex cursor-pointer items-center">
                              <input
                                type="checkbox"
                                value=""
                                className="peer sr-only"
                              />
                              <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-500 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                              <span className="text-sm font-medium text-gray-700 ms-3 dark:text-gray-300"></span>
                            </label>
                          </div>
                          <AiOutlineQuestionCircle className="cursor-pointer text-2xl" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BrokerDetails;
