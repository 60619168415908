import React from "react";

// Admin Imports
import {
  MainDashboard,
  SocialNetwork,
  Profile,
  Users,
  AiChat,
  Brokers,
  BrokerLicense
} from "./views/admin";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdPerson,
  MdLock,
  MdAccountBalanceWallet 
} from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import { BsBuildings } from "react-icons/bs";
import { SiChatbot } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { IoMdChatbubbles } from "react-icons/io";
import { TbDeviceAnalytics } from "react-icons/tb";
import { GrLicense } from "react-icons/gr";

const routes = [
  {
    name: "داشبورد اصلی",
    layout: "/admin",
    path: "default",
    icon: <IoHomeOutline className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "کارگزاری ها",
    layout: "/admin",
    icon: <BsBuildings className="h-6 w-6" />,
    path: "brokers",
    component: <Brokers />,
  },
  {
    name: "لایسنس",
    layout: "/admin",
    path: "license",
    icon: <GrLicense className="h-6 w-6" />,
    component: <BrokerLicense />,
    secondary: true,
  },
  {
    name: "رصد کارگزاری",
    layout: "/admin",
    icon: <TbDeviceAnalytics className="h-6 w-6" />,
    path: "analysis",
    component: <AiChat />,
  },
  // {
  //   name: "چت هوش مصنوعی",
  //   layout: "/admin",
  //   icon: <SiChatbot className="h-6 w-6" />,
  //   path: "ai-chat",
  //   component: <AiChat />,
  // },
  // {
  //   name: "کیف پول",
  //   layout: "/admin",
  //   icon: <MdAccountBalanceWallet className="h-6 w-6" />,
  //   path: "wallet",
  //   component: <Wallet />,
  // },
  // {
  //   name: "حساب کاربری",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  // {
  //   name: "ثبت نام",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
];
export default routes;
