import React, { useRef, useState } from "react";
import Chart from "react-apexcharts";
import { numberComma } from "utils/SplitNumber";

const WalletModal = ({ path, setOpenModal, data }) => {
  const [disabled, setDisabled] = useState(true);
  const [inputValue, setInputValue] = useState("");

  // const updateLicense = () =>{
  //     axios.post('path',data).then((response) =>{
  //         console.log(response)
  //     },(error)=>{
  //         console.log(error)
  //     })
  // }

  const [chartData, setChartData] = useState({
    seriesData: [10000000, 2000000],
    options: {
      labels: ["موجودی کل", "باقیمانده"],
      colors: ["#6AD2FF", "#5f8194"],
      chart: {
        width: "150px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
        formatter: function (val, opts) {
          return (
            val + " - " + numberComma(opts.w.globals.series[opts.seriesIndex])
          );
        },
      },
      dataLabels: {
        enabled: false,
      },
      hover: { mode: null },
      plotOptions: {
        donut: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
      fill: {
        colors: ["#6AD2FF", "#5f8194"],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        style: {
          color: "gray",
          fontSize: "12px",
          fontFamily: "IranSans",
          backgroundColor: "#000000",
        },
      },
    },
  });

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const isNumber = (value) => {
    return !isNaN(value) && value.trim() !== "";
  };
  return (
    <div className="w-[70%] xl:w-[50%] rounded-2xl bg-white dark:bg-navy-800 sm-max:w-[95%]">
      <div className="flex w-full items-start justify-between rounded-t border-b p-5">
        <h3 className="w-full text-xl font-semibold dark:text-white">
          ویرایش کیف پول
        </h3>

        <button
          onClick={() => setOpenModal(false)}
          type="button"
          className="bg-transparent ml-auto inline-flex items-center rounded-lg p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
          data-modal-toggle="product-modal"
        >
          <svg
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex items-start justify-between md-max:flex-col-reverse md-max:items-center p-4">
        {/* des */}
        <div className="flex flex-col  justify-start py-4 px-5">
          <form className="mt-3 grid grid-cols-6">
            <div className="col-span-6 md:col-span-3">
              <label
                for="wallet"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-600"
              >
                موجودی کل (تومان)
              </label>

              <input
                onChange={handleChange}
                type="number"
                name="wallet"
                id="wallet"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50  p-2.5 text-gray-900 shadow-sm outline-navy-200 focus:border-cyan-600 focus:ring-gray-600 dark:border-navy-500 dark:bg-navy-900 dark:text-gray-300 sm:text-sm"
                placeholder="10،000،000"
                required=""
              />
            </div>
          </form>
          <div className="mt-2">
            <button
              disabled={!isNumber(inputValue)}
              className={`w-[50%] rounded-xl  bg-navy-500
                 py-2 px-3 text-white md-max:w-full`}
            >
              ویرایش موجودی
            </button>
          </div>
        </div>
        {/* chart */}
        <div className="flex">
          <Chart
            options={chartData.options}
            series={chartData.seriesData}
            type="donut"
          />
        </div>
      </div>
    </div>
  );
};

export default WalletModal;
