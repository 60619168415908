import React from "react";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Banner } from "components";
import { postData } from "../default/variables/columnsData";
import BrokerCard from "./components/BrokerCard";
import LicenseControlModal from "./components/LicenseControlModal";

const BrokerLicense = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState(null);

  useState(() => {
    console.log(
      `Modal : ${openModal} , Type: ${modalType} , Data :${modalData}`
    );
  }, [openModal]);
  return (
    <div className="relative mt-3 grid h-full grid-cols-1 gap-5">
      {/* post modal  */}
      <LicenseControlModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalType={modalType}
        modalData={modalData}
      />

      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        {/*  Banner */}
        <Banner
          title="راهنمای لایسنس"
          description="با تماشای ویدیو راهنمای تنظیم و مدیریت لایسنس برای کارگزاری ها میتوانید به تمامی قسمت های این صفحه مسلط بشید"
        />
        {/*  Header */}
        <div className="mb-4 mt-5 flex h-[50px] items-center justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            کارگزاری ها
          </h4>
          <div className="flex h-full items-center rounded-full bg-white text-navy-700 dark:bg-navy-700 dark:text-white shadow-2xl xl:w-[225px]">
            <p className="text-xl pe-2 ps-3">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              placeholder="جستجو"
              className="block h-full w-full rounded-full  text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-700 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
          </div>
        </div>
        {/* date picker */}
        {/* <div className="my-4 flex h-[50px] w-full items-center gap-5 px-4">
          <div className="flex items-center gap-2">
            <span className="text-sm font-bold dark:text-white"> شروع :</span>
            <DatePicker
              className=""
              inputPlaceholder="انتخاب تاریخ"
              shouldHighlightWeekends
              locale="fa"
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm font-bold dark:text-white"> پایان :</span>
            <DatePicker
              className=""
              inputPlaceholder="انتخاب تاریخ"
              shouldHighlightWeekends
              locale="fa"
            />
          </div>
        </div> */}

        {/*  trending post */}

        {/* Reported Posts */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
          {postData.map((post, index) => {
            return (
              <BrokerCard
                key={index}
                modalType={modalType}
                setModalType={setModalType}
                openModal={openModal}
                setOpenModal={setOpenModal}
                modalData={modalData}
                setModalData={setModalData}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BrokerLicense;
