import React from "react";
import { useState } from "react";
import avatar from "assets/img/profile/logo-white.png";
import { IoIosTimer, IoMdWallet, IoMdCall } from "react-icons/io";
import { RiSoundModuleFill } from "react-icons/ri";
import TooltipHorizon from "components/tooltip";
import { Tooltip } from "@chakra-ui/tooltip";
const BrokerCard = ({
  openModal,
  setOpenModal,
  modalType,
  setModalType,
  modalData,
  setModalData,
}) => {
  const modalHandler = (type, data) => {
    setModalData(data);
    setModalType(type);
    setOpenModal(true);
  };

  return (
    <div className="max-w-sm rounded-2xl bg-white p-6 dark:bg-navy-800 dark:text-white">
      {/* <span className="rounded-full bg-navy-600 px-2 py-1 text-xs font-bold text-white">
      PRO
    </span> */}
      <img
        className="mx-auto mt-4 h-24 w-24 rounded-full border-2 border-navy-500 bg-gray-800 dark:bg-navy-500"
        src={avatar}
        alt="user"
      />
      <h3 className="mt-4 text-center text-lg font-bold dark:text-white">
        سهم آشنا
      </h3>
      <h6 className="text-center text-sm uppercase text-gray-400"></h6>
      <p className="mt-2 text-center text-sm leading-6">
        <span>تاریخ اتمام :</span>
        <span className="font-bold">1405/02/04</span> <br />
        <span>موجودی کیف پول :</span>
        <span className="font-bold">5،000،000</span>
      </p>
      <div className="mt-4 flex flex-row-reverse justify-around">
        {/* <button className="rounded-full bg-navy-600 hover:bg-navy-400 transition-all ease-out px-4 py-2 text-white">
        نمایش جزییات
      </button> */}
        <Tooltip
          className="rounded-lg bg-lightPrimary py-2 px-2 shadow-lg"
          placement={"top"}
          label="تماس"
          hasArrow={true}
        >
          <a
            href="tel:02156543"
            className="rounded-full border border-navy-500 bg-navy-500 px-4 py-2 text-white"
          >
            <IoMdCall />
          </a>
        </Tooltip>
        <Tooltip
          placement="top"
          label={"ویرایش تاریخ"}
          className="rounded-lg bg-lightPrimary py-2 px-2 shadow-lg"
        >
          <button
            onClick={() => modalHandler("date", { inventory: 5000000 })}
            href="tel:02156543"
            className="rounded-full border border-navy-500 bg-navy-500 px-4 py-2 text-white"
          >
            <IoIosTimer />
          </button>
        </Tooltip>
        <Tooltip
          className="rounded-lg bg-lightPrimary py-2 px-2 shadow-lg"
          placement={"top"}
          label="تنظیم لایسنس"
          hasArrow={true}
        >
          <button
            onClick={() =>
              modalHandler("license", { inventory: 5000000 })
            }
            href="tel:02156543"
            className="rounded-full border border-navy-500 bg-navy-500 px-4 py-2 text-white"
          >
            <RiSoundModuleFill />
          </button>
        </Tooltip>
        <Tooltip
          className="rounded-lg bg-lightPrimary py-2 px-2 shadow-lg"
          placement="top"
          label="کیف پول"
        >
          <button
            onClick={() => modalHandler("wallet", { inventory: 5000000 })}
            href="tel:02156543"
            className="rounded-full border border-navy-500 bg-navy-500 px-4 py-2 text-white"
          >
            <IoMdWallet />
          </button>
        </Tooltip>
      </div>
      <div className="mt-6">
        <h6 className="text-sm uppercase text-gray-400 mr-3">ابزار ها</h6>
        <ul className="mt-2 flex list-inside list-none items-center justify-around text-sm">
          <li
            className={`rounded-xl border border-gray-500 px-2 py-1 text-sm text-gray-500`}
          >
            چت ربات
          </li>
          <li
            className={`rounded-xl border border-gray-500 px-2 py-1 text-sm text-gray-500`}
          >
            شبکه اجتماعی
          </li>
          <li
            className={`rounded-xl border border-gray-500 px-2 py-1 text-sm text-gray-500`}
          >
           تریدینگ ویو
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BrokerCard;
