import React, { useState } from "react";
import Chart from "react-apexcharts";
import { numberComma } from "utils/SplitNumber";

const TimeModal = ({ path, setOpenModal, data }) => {
  const [chartData, setChartData] = useState({
    seriesData: [250, 365],
    options: {
      labels: ["روزهای گذشته", "روز باقیمانده"],
      colors: ["#6AD2FF", "#5f8194"],
      chart: {
        width: "150px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      dataLabels: {
        enabled: false,
      },
      hover: { mode: null },
      plotOptions: {
        donut: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },

      fill: {
        colors: ["#6AD2FF", "#5f8194"],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        style: {
          color: "gray",
          fontSize: "12px",
          fontFamily: "IranSans",
          backgroundColor: "#000000",
        },
      },
    },
  });
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const isNumber = (value) => {
    return !isNaN(value) && value.trim() !== "";
  };

  // const updateLicense = () =>{
  //     axios.post('path',data).then((response) =>{
  //         console.log(response)
  //     },(error)=>{
  //         console.log(error)
  //     })
  // }

  return (
    <div className="w-[90%] rounded-2xl bg-white xl:w-[50%]">
      <div className="flex w-full items-start justify-between rounded-t border-b p-5">
        <h3 className="w-full text-xl font-semibold dark:text-white">
          مدیریت تاریخ
        </h3>

        <button
          onClick={() => setOpenModal(false)}
          type="button"
          className="bg-transparent ml-auto inline-flex items-center rounded-lg p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
          data-modal-toggle="product-modal"
        >
          <svg
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex items-start justify-between p-4 md-max:flex-col-reverse md-max:items-center">
        {/* des */}
        <div className="flex justify-start py-4 px-5">

        </div>
        {/* chart */}
        <div className="flex">
          <Chart
            options={chartData.options}
            series={chartData.seriesData}
            type="donut"
          />
        </div>
      </div>
    </div>
  );
};

export default TimeModal;
