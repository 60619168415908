import { Banner } from "components";
import React, { useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import CreateBroker from "./components/CreateBroker";
import Switch from "components/switch";
import BrokerDetails from "./components/BrokerDetails";

const Brokers = () => {
  const [openModal, setOpenModal] = useState(false);
  const [detailModal , setDetailModal] = useState(false)
  const [searchBar, setOpenSearchBar] = useState(false);
  return (
    <>
      <div className="relative mt-5 flex flex-col">
        <CreateBroker openModal={openModal} setOpenModal={setOpenModal} />
        <BrokerDetails openModal={detailModal} setOpenModal={setDetailModal}/>
        <Banner setOpenModal={setOpenModal} />

        <div className="mt-2 flex items-center justify-between px-4 py-3">
          <button
            onClick={() => setOpenModal(true)}
            className="group flex items-center justify-center rounded-2xl bg-navy-500 p-3 text-white transition-all ease-out hover:bg-navy-400"
          >
            افزودن کارگزاری
            <FaPlus className="mr-2 transition-all ease-out group-hover:text-xl" />
          </button>
          <div className="flex gap-3">
            <button
              onClick={() => setOpenSearchBar(true)}
              className={`group rounded-2xl bg-navy-500 p-3 text-white transition-all ease-in ${
                searchBar ? "flex w-[200px] items-center" : "w-auto"
              }`}
            >
              <input
                type="text"
                placeholder="جستو جو"
                className={`${
                  searchBar ? "" : "hidden"
                } w-full bg-navy-500 text-white outline-none`}
              />
              <IoSearch className="transition-all ease-out group-hover:text-xl" />
            </button>
            <button className="group rounded-2xl bg-navy-500 p-3 text-white">
              <IoMdRefresh className="transition-all ease-out group-hover:text-xl" />
            </button>
          </div>
        </div>

        {/* table */}
        <div className="light-scroll h-auto w-full  overflow-x-auto rounded-xl bg-gray-200 dark:bg-navy-900">
          <table className="w-full">
            <thead className="text-xs uppercase text-gray-700  dark:bg-blueSecondary dark:text-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                  نام کارگزاری
                </th>
                <th scope="col" className="px-6 py-3">
                  ت . ش
                </th>
                <th scope="col" className="px-6 py-3">
                  ت . پ
                </th>
                <th scope="col" className="px-6 py-3">
                  موجودی
                </th>
                <th scope="col" className="px-6 py-3">
                  وضعیت
                </th>
                <th scope="col" className="px-6 py-3">
                  ویرایش
                </th>
                <th scope="col" className="px-6 py-3">
                  نمایش
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 10 }, (_, index) => index + 1).map(
                (list, index) => {
                  return (
                    <tr
                      key={index}
                      className="border-b odd:bg-lightPrimary dark:border-gray-700 odd:dark:bg-gray-900 even:dark:bg-gray-900"
                    >
                      <th
                        scope="row"
                        className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                      >
                        سهم آشنا
                      </th>
                      <td className="px-6 py-4 text-center">
                        <span className="dark:text-gray-300">1403/04/01</span>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <span className="dark:text-gray-300">1405/04/01</span>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <span className="dark:text-white">10،000،000</span>
                      </td>
                      <td className="px-6 py-4">
                        <Switch />
                      </td>
                      <td className="px-6 py-4 text-center">
                        <button
                          className="text-center font-medium text-blue-600  dark:text-blue-500"
                        >
                          ویرایش
                        </button>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <button
                        onClick={()=> setDetailModal(true)}
                          className="text-center bg-navy-400 dark:bg-blueSecondary dark:hover:bg-navy-300  hover:bg-navy-300 text-white font-medium  p-2 rounded-xl transition-all ease-out"
                        >
                          جزییات
                        </button>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>

          <div className="flex w-full items-center justify-center p-3">
            <nav aria-label="Page navigation example">
              <ul className="inline-flex -space-x-px text-sm">
                <li>
                  <a
                    href="#"
                    className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 ms-0 rounded-s-lg border-e-0 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-navy-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    قبلی
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-navy-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    1
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-navy-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    2
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    aria-current="page"
                    className="flex h-8 items-center justify-center border border-gray-300 bg-blue-50 px-3 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-navy-700 dark:text-white"
                  >
                    3
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-navy-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    4
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-navy-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    5
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-navy-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    بعدی
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brokers;
